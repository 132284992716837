<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      :visible="visibleModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="hideModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Seguimentos') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >

            <div
              v-if="data.showListview() === true"
              class="mt-1 mb-2"
            >
              <list-data
                :data="listviewData"
                :view-seguimento="viewSeguimento"
                :with-name-lead="showNameLead"
              />
            </div>

            <div
              v-if="listviewData.length === 0"
              class="no-results text-center"
              :class="{'show': !listviewData.length}"
            >
              <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
                {{ txt.notFind }}
              </h5>
              <h5 v-if="data.init === 1">
                {{ txt.loading }}
              </h5>
              <div v-if="(data.totalFieldsFilter===0) && (data.init === 0)">
                <b-alert
                  class="mb-0 mt-1"
                  variant="primary"
                  show
                >
                  <div class="alert-body">
                    <span>{{ txt.default }}</span>
                  </div>
                </b-alert>
              </div>
            </div>

            <infinite-loading
              ref="elmInfiniteLoading"
              force-use-infinite-wrapper=".b-sidebar-scrollarea-form"
              @infinite="getSeguimentosInfinite"
            >
              <div slot="no-more" />
              <div slot="no-results" />
            </infinite-loading>

          </b-container>
        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BButton, BContainer,
} from 'bootstrap-vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import modelSeguimentos from '@store-modules/crm/leads/seguimentos'
import {
  onMounted, onUnmounted, watch, toRefs, ref,
} from '@vue/composition-api'
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import { configSettingsScroll } from '@core-custom/utils/ui'
import ListData from './listData.vue'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BSidebar,
    BButton,
    BContainer,
    VuePerfectScrollbar,
    InfiniteLoading,
    ListData,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    tabSelected: {
      type: Object,
      default: () => {},
      required: false,
    },
    showNameLead: {
      type: Boolean,
      default: false,
      required: false,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed:
  {
    ...mapGetters('allSeguimentosModalDashboard', ['listviewData', 'data', 'txt']),
  },
  methods: {
    async hideModal(open) {
      if ((this.visibleModal === true) && (open === false)) {
        await this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/cancelLoadListviewDataDashboardByTab`)
        this.$emit('closeModal')
      }
    },
    async getSeguimentosInfinite() {
      await this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/loadListviewDataDashboardByTab`, {
        init: true,
        tab: this.tabSelected.tab || '',
      }).then(res => {
        if (res.finish === true) {
          this.$refs.elmInfiniteLoading.stateChanger.complete()
        } else {
          this.$refs.elmInfiniteLoading.stateChanger.loaded()
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.$refs.elmInfiniteLoading.stateChanger.loaded()
      })

      await this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/nextCurrentPage`)
    },
    viewSeguimento(row) {
      this.$emit('update:visible', false)
      this.$emit('update:refresh', false)
      this.$emit('viewSeguimento', row)
    },
  },
  setup(props) {
    const NAMESPACE_SEGUIMENTO_FORM = 'allSeguimentosModalDashboard'

    if (!store.hasModule(NAMESPACE_SEGUIMENTO_FORM)) {
      store.registerModule(NAMESPACE_SEGUIMENTO_FORM, modelSeguimentos)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SEGUIMENTO_FORM)) store.unregisterModule(NAMESPACE_SEGUIMENTO_FORM)
      })
    }

    const propsData = toRefs(props)
    const visibleModal = ref(false)
    const elmInfiniteLoading = ref(null)

    onMounted(() => {
      const init = async () => {
        try {
          if (elmInfiniteLoading) {
            await store.dispatch(`${NAMESPACE_SEGUIMENTO_FORM}/resetListviewDataDashboardByTab`)
            elmInfiniteLoading.value.stateChanger.reset()
          }
        } catch (err) {
        //
        }
      }

      watch(propsData.visible, val => {
        visibleModal.value = val
      }, { immediate: true })

      watch(propsData.refresh, val => {
        if (val === true) {
          init()
        }
      }, { immediate: true })

      init()
    })

    return {
      NAMESPACE_SEGUIMENTO_FORM,
      visibleModal,
      elmInfiniteLoading,
      configSettingsScroll,
    }
  },
}
</script>
